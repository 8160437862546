.App {
  height: 96vh;
  padding: 2vh 0;
  display: flex;
  overflow: hidden;
  margin: auto;
  background: #000;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background: white; */
}

.title {
  display: flex;
  font-weight: bold;
  margin: 2vh 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.logo {
  height: calc(10vmin);
  padding: 0 4%;
  /* flex: 1; */
  /* background: #ffffff; */
}
.titleText {
  color: white;
  font-weight: bold;
  font-size: calc(1px + 12vmin);
  margin: 2vh 0;
}

.subTitle {
  color: white;
  font-weight: bold;
  font-size: calc(1px + 7vmin);
  margin: 1vh;
}

.imgContainer {
  display: flex;
  /* flex-direction: column; */
  margin: 1vh;
  overflow: hidden;
  justify-content: space-around;
  align-items: center;
}

.screenshot {
  max-height: 100%;
  max-width: 30%;
  border-radius: 20px;
  overflow: hidden;
}

.buttonContainer {
  justify-items: center;
  align-items: center;
}

.button {
  align-self: center;
  margin: 2vh;
  background: white;
  /* width: calc(20px + 2vmin); */
  padding: 2vmin 8vmin;
  border-radius: 10px;
  border-width: 0;
  color: black;
  font-size: calc(8px + 2vmin);
  font-weight: bold;
}

#foot {
  width: 30%;
  display: flex;
  justify-content: space-around;
}

.prompt {
  color: white;
  font-size: 2vh;
  margin: 2vh 0;
  font-size: calc(4px + 2vmin);
  font-weight: bold;
}

.followUs {
  height: calc(5vh);
}

.note {
  color: white;
  font-size: 2vh;
  margin: 2vh 0;
  font-size: calc(4px + 2vmin);
  font-weight: bold;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
